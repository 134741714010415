<template>
    <div :style='{"font-size":"7pt", "line-height": "normal"}'>

        <div :style='{...estilos.tablaBorde, ...{"text-align":"center", "font-weight": "400", "background-color": "#D3D3D3"}}'>
            Estudios de laboratorio
        </div>
        <div v-if="datos">
            <div
                v-for="itm in datos.laboratorio"
                :key="itm.id"
            >
                Solicitado: {{itm.dateCreated.split(' ')[1]}} ({{itm.medico}})
                <ul v-for="(itm2, id) in itm.items" :key="id">
                    <li>{{itm2.itemNombre}}</li>
                </ul>
                <span v-if="itm.observaciones">Observaciones: {{itm.observaciones}}</span>
            </div>
            <hr>
        </div>


        <div :style='{...estilos.tablaBorde, ...{"text-align":"center", "font-weight": "400", "background-color": "#D3D3D3"}}'>
            Estudios de IMAGENOLOGÍA
        </div>

        <div
                v-for="itm in datos.rx"
                :key="itm.id"
            >
                Solicitado: {{itm.dateCreated.split(' ')[1]}} ({{itm.medico}})<br/>
                Estudio:<br/>{{itm.estudioNombre}} - {{itm.descripcion}}
                <span v-if="itm.observaciones"><br/>Observaciones: {{itm.observaciones}}</span>
            </div>
            <hr>
    </div>
</template>

<script>
import { printEnfe } from '@/mixins/enfermeria/printEnfeMixin.js'
export default {
    mixins: [printEnfe],

}
</script>

<style>

</style>